import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import styles from './index.module.scss';
import { eggBin } from '../../../static/egg.json';
import ArrowButton from '../arrow-button';

export default function Carousel({
    start = 0,
    muted = false,
    children,
    egg = []
}) {
    const audioCtx = typeof window !== 'undefined' ? new (window.AudioContext || window.webkitAudioContext)() : null;

    const createAudioBuffer = (url, setBuffer) => {
        const request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer';
        request.addEventListener(
            'load',
            async event => {
                const request = event.target;
                const source = audioCtx.createBufferSource();
                audioCtx.decodeAudioData(request.response, res => {
                    console.log(res);
                    setBuffer(res);
                });
            },
            false
        );
        request.send();
    };

    const play = useCallback(
        buffer => {
            if (buffer && !muted) {
                const source = audioCtx.createBufferSource();
                source.buffer = buffer;
                source.connect(audioCtx.destination);
                source.start ? source.start(0) : source.noteOn(0);
            }
        },
        [muted, audioCtx]
    );

    const [pos, setPos] = useState(start);
    const [combo, setCombo] = useState([]);
    const [touch, setTouch] = useState({ x: [0, 0], y: [0, 0] });

    const [openHi, setOpenHi] = useState();
    const [hi1, setHi1] = useState();
    const [hi2, setHi2] = useState();
    const [cowbell, setCowbell] = useState();

    useEffect(() => {
        createAudioBuffer('/assets/sounds/open_hi.ogg', setOpenHi);
        createAudioBuffer('/assets/sounds/hi1.mp3', setHi1);
        createAudioBuffer('/assets/sounds/hi2.mp3', setHi2);
        createAudioBuffer('/assets/sounds/cowbell.ogg', setCowbell);
    }, []);

    // Renders the carousel grid from children
    const carousel = useMemo(() => {
        return React.Children.map(children, (el, i) => {
            const current =
                Math.abs(i + pos + children.length) % children.length;
            let className = styles[`pos${current}`];
            let href = el.props.href;
            let onClick = el.props.onClick;
            if (Math.abs(i + pos + children.length) % children.length === 1) {
                className += ` ${styles.active}`;
                onClick = event => {
                    play(openHi);
                    if (el.props.onClick) {
                        el.props.onClick();
                    }
                };
            } else {
                className += ` ${styles.inactive}`;
                href = '#';
                onClick = event => {
                    if (current === 0) {
                        setPos((pos + 1) % children.length);
                    } else {
                        setPos((pos - 1) % children.length);
                    }
                    play(hi1);
                };
            }
            return React.cloneElement(el, {
                key: el.key,
                className,
                onClick,
                href
            });
        });
    }, [children, pos, setPos, play, openHi, hi1]);

    // Keyboard handler
    const handleKeyDown = useCallback(
        ({ key }) => {
            let newPos = pos;
            switch (key) {
                case 'ArrowLeft':
                    play(hi1);
                    newPos = (pos - 1) % children.length;
                    setPos(newPos);
                    break;
                case 'ArrowRight':
                    play(hi2);
                    newPos = (pos + 1) % children.length;
                    setPos(newPos);
                    break;
                default:
                    break;
            }
            if (egg?.length) {
                setCombo(
                    [...combo, key].slice(
                        Math.max(combo.length - egg.length + 1, 0)
                    )
                );
            }
        },
        [pos, setPos, children, combo, setCombo, egg, play]
    );

    // Touch gesture handling on mobile
    const handleGesture = useCallback(() => {
        var swiped = 'swiped: ';
        if (touch.x[1] < touch.x[0]) {
            console.log(swiped + 'left!');
            handleKeyDown({ key: 'ArrowLeft' });
        }
        if (touch.x[1] > touch.x[0]) {
            console.log(swiped + 'right!');
            handleKeyDown({ key: 'ArrowRight' });
        }
        if (touch.y[1] < touch.y[0]) {
            console.log(swiped + 'down!');
        }
        if (touch.y[1] > touch.y[0]) {
            console.log(swiped + 'left!');
        }
        if (touch.y[1] === touch.y[0]) {
            console.log('tap!');
        }
    }, [touch, handleKeyDown]);

    // Set start coordinates of mobile touch interaction
    const touchStart = useCallback(
        evt => {
            console.log(evt);
            if (evt.changedTouches.length) {
                setTouch(prev => {
                    console.log(prev);
                    prev.x[0] = evt.changedTouches[0].screenX;
                    prev.y[0] = evt.changedTouches[0].screenY;
                    return prev;
                });
            }
        },
        [setTouch]
    );

    // Set end coordinates of mobile touch interaction
    const touchEnd = useCallback(
        evt => {
            if (evt.changedTouches.length) {
                setTouch(prev => {
                    prev.x[1] = evt.changedTouches[0].screenX;
                    prev.y[1] = evt.changedTouches[0].screenY;
                    return prev;
                });
            }
            handleGesture();
        },
        [setTouch, handleGesture]
    );

    // Add event listeners for swipe and keyboard navigation
    useEffect(() => {
        if (children.length) {
            window.addEventListener('keydown', handleKeyDown);
            // window.addEventListener('touchstart', touchStart, false);
            // window.addEventListener('touchend', touchEnd, false);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                // window.removeEventListener('touchstart', touchStart);
                // window.removeEventListener('touchend', touchEnd);
            };
        }
    }, [children, handleKeyDown, handleGesture, touchStart, touchEnd]);

    const renderContent = useMemo(() => {
        if (JSON.stringify(combo) === JSON.stringify(egg)) {
            play(cowbell);
            return <code className={styles.greenText}>{eggBin}</code>;
        }
        return carousel;
    }, [combo, egg, cowbell, play, carousel]);

    return (
        <div className={styles.root}>
            {renderContent}
            <div className={styles.mobileNav}>
                <ArrowButton
                    variant="back"
                    fullWidth
                    onClick={() => handleKeyDown({ key: 'ArrowLeft' })}
                />
                <ArrowButton
                    variant="forward"
                    fullWidth
                    onClick={() => handleKeyDown({ key: 'ArrowRight' })}
                />
            </div>
        </div>
    );
}

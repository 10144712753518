import React from 'react';
import styles from './index.module.scss';

export default function Logo({
    className = '',
    variant,
    onClick = () => {},
    href = '#',
    children = 'noCAPTCHA.live/'
}) {
    const handleClick = evt => {
        onClick(evt);
        if (!href || href === '#') {
            evt.preventDefault();
        }
    };
    return (
        <figure
            className={`${styles.root} ${className}`}
            onClick={handleClick}
            role="button"
            tabIndex={0}
        >
            <a className={styles[variant]} href={href}>
                <div alt="noCAPTCHA">{children}</div>
            </a>
        </figure>
    );
}

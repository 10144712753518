import React, { useEffect, useRef, useState } from 'react';
import Layout from '../components/layout';
import Logo from '../components/logo';
import Carousel from '../components/carousel';
import SEO from '../components/seo';

export default function IndexPage() {
    const root = useRef();
    const [darkMode, setDarkMode] = useState(1);
    const [audio, setAudio] = useState(1);

    const sw = {
        setDarkMode,
        setAudio,
        values: { darkMode, audio }
    };
    const egg = [
        'ArrowLeft',
        'ArrowRight',
        'ArrowRight',
        'ArrowLeft',
        'ArrowRight',
        'ArrowLeft',
        'ArrowRight'
    ];

    useEffect(() => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                navigator.userAgent
            )
        ) {
            setAudio(0);
        }
    }, []);

    return (
        <Layout ref={root} sw={sw}>
            <SEO title="i am not a robot." />
            <Carousel start={0} muted={!audio} egg={egg}>
                <Logo key="mixes" variant="logo" href="/mixes">
                    MIXES
                </Logo>
                <Logo
                    key="home"
                    variant="logo"
                    href="https://www.youtube.com/watch?v=Gnw5H_mTwVk&list=PLKmxw_BCAsEXv6-2dY87WH_KruIaky6um"
                />
                <Logo key="live" variant="logo" href="/live">
                    LIVESTREAM
                </Logo>
                <Logo key="merch" variant="logo" href="/merch">
                    MERCH
                </Logo>
            </Carousel>
        </Layout>
    );
}
